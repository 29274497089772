import React from "react";
import "./about.css";
import { FiAward, FiUsers } from "react-icons/fi";
import { FaRegFolder } from "react-icons/fa";
import ME from "../../assets/A1.jpg";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FiAward className="about_icon" />
              <h5>Experience</h5>
              <small>3+ year working</small>
            </article>

            <article className="about_card">
              <FiUsers className="about_icon" />
              <h5>Clients</h5>
              <small>200+ Wordwide</small>
            </article>

            <article className="about_card">
              <FaRegFolder className="about_icon" />
              <h5>Projects</h5>
              <small>80+ Completed</small>
            </article>
          </div>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. 
            Neque ipsam eaque possimus asperiores soluta, ipsa perferendis! Officiis ut laudantium molestiae nesciunt iste
            exercitationem placeat earum aut, itaque inventore praesentium ipsam?
  
          </p>
          <a href="#contact" className="btn btn-primary">Let 's Talk</a>
        </div>
      </div>
    </section>
  );
};

export default About;
